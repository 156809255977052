<template>
    <vx-card :title="'Work Order Execution - Execution Form'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order Description</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Bill Of Material ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="warehouseName" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 40%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="text-align: center;" colspan="3">Actual</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in skuLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.actual_lines.length">
                                    <vs-input :value="tr.sku_code+' / '+tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.actual_lines.length">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.actual_lines.length">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].storage_area" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].batch_number" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.actual_lines[0].expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                            </tr>

                            <!-- add actual line -->
                            <template v-for="(trx, indexActual) in skuLines[index].actual_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexActual" v-if="indexActual > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch_number" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br><hr><br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 35%">SKU Description</th>
                            <th style="width: 10%">HU</th>
                            <th style="padding: 5px"></th>
                            <th style="width: 7%">Planned Qty</th>
                            <th style="width: 7%">Actual Qty</th>
                            <th style="padding: 5px"></th>
                            <th style="width: 5%">Delta</th>
                            <th>Settlement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, index) in parentLines" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="tr.sku_code+' / '+tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px"></td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="tr.actual_qty" style="width: auto;" @input="(val) => { onChangeActualQty(val, index) }"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px"></td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="tr.delta_qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <multiselect 
                                    v-model="tr.selectedSettlement" 
                                    placeholder="Select settlement"
                                    :options="settlementOption" 
                                    track-by="value"
                                    label="name"
                                    :multiple="false" 
                                    :searchable="false"
                                    :allow-empty="false"
                                    :select-label="''"
                                    deselect-label=""
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseName: null,
            actualQty: 0,
            settlementOption: [
                {
                    value: "0",
                    name: "Expense",
                },
                {
                    value: "1",
                    name: "Capitalize",
                },
            ],
            parentLines: [
                {
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    actual_qty: 0,
                    delta_qty: 0,
                    selectedSettlement: null,
                }
            ],
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/work-order-execution/execute-form/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.woCode = resp.data.wo_code
                    this.description = resp.data.wo_description
                    this.bomCode = resp.data.bom_code
                    this.warehouseID = resp.data.warehouse_id
                    this.warehouseName = resp.data.warehouse_name
                    this.parentLines = resp.data.parent_lines
                    this.skuLines = resp.data.sku_lines

                    this.parentLines.forEach(val => {
                        val.selectedSettlement = this.settlementOption.find(line => {
                            return line.name == val.selectedSettlement
                        });
                    })
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }

                this.parentLines.forEach((val) => {
                    if (val.actual_qty != null) {
                        val.delta_qty = val.qty - val.actual_qty
                    }
                })
            })
            .catch(error => {
                console.log(error)
                this.$vs.loading.close();
            });
        },
        handleSave() {
            var lines = [];
            this.parentLines.forEach((val) => {
                if (val.actual_qty == null) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Actual Qty required",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });

                    return false
                }

                lines.push({
                    settlement: val.selectedSettlement.name,
                    actual_qty: Number(val.actual_qty),
                })
            });

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    let formData = new FormData();
                    formData.append("lines", JSON.stringify(lines));

                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/work-order-execution/save-execute/" + this.id, formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check-circle",
                            });
                            this.$vs.loading.close();
                            this.handleBack()
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "work-order-execution",
            });
        },
        onChangeActualQty(val, index) {
            if (val > this.parentLines[index].qty) {
                this.$vs.notify({
                    title: "Error",
                    text: "Actual Qty must not be greater than Planned Qty",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                this.parentLines[index].actual_qty = this.parentLines[index].qty
                return false
            }

            if (val < 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "minimum Actual Qty is 0",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                this.parentLines[index].actual_qty = 0
                return false
            }

            this.parentLines[index].delta_qty = this.parentLines[index].qty - val
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>